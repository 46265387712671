import Prism from 'prismjs';
import { useEffect, useState } from "react";
import "../libs/prism.css";

interface Props {
  json?: string
}

export default function JsonDialog({ json }: Props) {

  const [formatted, setFormatted] = useState<string>("");

  const format = async (json?: string) => {
    if (!json) return "";
    return JSON.stringify(JSON.parse(json), null, 2)
  }

  useEffect(() => {
    setFormatted("Processing");

    format(json).then( (v) => {      
      setFormatted(Prism.highlight(v, Prism.languages.js, 'js'))
    })
    
    
  }, [json]);

  return json ? <>
    <pre style={{backgroundColor: "black"}}>
      <code className="language-javascript" 
            dangerouslySetInnerHTML={{__html: formatted}}>
      </code>
    </pre>
  </> : <></>
}