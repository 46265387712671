import { useLoaderData } from "react-router-dom";
import { ReportData } from "../common/fetcher";
import Vulnerabilities from "../components/Vulnerabilities";
import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import Report from "../components/Json";

export default function ReportDetails() {
  const data: ReportData = useLoaderData() as ReportData;
  //const user = useRouteLoaderData("root");
  const [open, setOpen] = useState(false);
  useEffect(() => {
    console.log("DATA", data)
  }, [data])

  console.log("Render")

  return <>
    <div style={{ display: "flex", flexGrow: 1, justifyContent: "end" }}>
      <Button onClick={() => setOpen(true)}>View Source</Button>
    </div>

    <Vulnerabilities parent_id={data.id} />

    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={() => setOpen(false)}
      open={open}>
      <DialogContent>
        <Report json={data.source} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  </>

}