import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { DataGrid, GridColDef, GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Vulnerability } from "../common/dtos";
import fetcher, { Filters } from "../common/fetcher";
import VulnerabilityDetail from "./Vulnerability";

interface Props {
  parent_id?: number | string
}

export default function Vulnerabilities({ parent_id }: Props) {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<number>(0);
  const [selected, setSelected] = useState<Vulnerability | undefined>(undefined);
  const [orderingModel, setOrderingModel] = useState<GridSortModel>([{
    field: "build_created_at",
    sort: "desc"
  }])
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const filters: Filters = useMemo(() => {
    return {
      'report_id': {
        value: `${parent_id}`,
        op: "="
      }
    }
  }, [parent_id]);

  const columns: GridColDef[] = [
    { field: 'filename', headerName: 'Filename', flex: 2 },
    { field: 'start_line', headerName: 'Starts at' },
    { field: 'end_line', headerName: 'Ends at' },
    { field: 'name', headerName: 'Name', flex: 3, minWidth: 250 },
    { field: 'severity', headerName: 'Severity' },
  ];

  const getItems = useCallback(async () => {
    setLoading(true)

    fetcher('api/vulnerabilities', orderingModel, filters, paginationModel)
      .then(async res => {
        const { results, count } = await res.json();
        setData(results)
        setCount(count);
      })
      .finally(() => setLoading(false));
  }, [paginationModel, orderingModel, filters]);

  useEffect(() => {
    getItems()
  }, [getItems]);

  /*
  useEffect(() => {
    // console.log("vulns", parent_id)
    if (parent_id) {
      setFilters(filters => {
        if (filters['report_id']?.value !== parent_id) {
          return {
            ...filters,
            'report_id': {
              value: `${parent_id}`,
              op: "="
            }
          }
        } else {
          return filters
        }
      })      
    }
    
  }, [getItems, parent_id])
  */

  return <>
    <Box>
      <DataGrid
        autoHeight
        disableRowSelectionOnClick
        paginationModel={paginationModel}
        sortModel={orderingModel}
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={setOrderingModel}
        paginationMode="server"
        loading={isLoading}
        pageSizeOptions={[10, 25, 100]}
        rowCount={count} rows={data} columns={columns}
        onRowClick={(p) => setSelected(p.row)}
      />
    </Box>
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={() => setSelected(undefined)}
      open={selected !== undefined}>
      <DialogContent>
        <VulnerabilityDetail vuln={selected!} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setSelected(undefined)}>Close</Button>
      </DialogActions>
    </Dialog>
  </>
}
