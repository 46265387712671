import { Typography } from "@mui/material";
import { VulnerabilityIdentifier } from "../common/dtos";

interface Props {
    id: VulnerabilityIdentifier
}
export default function VulnerabilityId({id}: Props) {
    let item = <Typography>{id.name} ({id.type}): {id.value}</Typography>
    if (id.url!=="") {
        item = <a href={id.url} rel="noreferrer noopener" target="_blank">{item}</a>
    }

    return item;
}