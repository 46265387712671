import { Container } from '@mui/material';
import { AuthProvider, TAuthConfig, TRefreshTokenExpiredEvent } from "react-oauth2-code-pkce";
import './App.css';
import Routes from './components/Routes';
import { StateProvider } from './providers/StateProvider';
import { StrictMode } from 'react';
const authConfig: TAuthConfig = {
  clientId: '9b7ed6ecf9710114a865fa9506347b200fc73285bb9051c0d21458c02ff4939e',
  // gloas-13cf6b4adba4a6778b7fa1e4b2a9feb3b0e7a9d20f3a4a2d02675b7f19f9edd9
  authorizationEndpoint: 'https://gitlab.com/oauth/authorize',
  tokenEndpoint: 'https://gitlab.com/oauth/token',
  // redirectUri: 'https://reports.postalytics-mq.com/',
  redirectUri: process.env.REACT_APP_PUBLIC_URL || '',
  scope: 'profile email openid',
  decodeToken: false,
  autoLogin: false,
  onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) => window.confirm('Session expired. Refresh page to continue using the site?') && event.login(),
}

function App() {
  return (
    <StrictMode>
      <AuthProvider authConfig={authConfig}>
        <StateProvider>
          <Container maxWidth="lg" >
            <Routes />
          </Container>
        </StateProvider>
      </AuthProvider>
    </StrictMode>
  );
}

export default App;
