import { Box, Button } from "@mui/material";
import { DataGrid, GridColDef, GridPaginationModel, GridRowParams, GridSortModel } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import fetcher from "../common/fetcher";
import Bar from '../components/Bar';
import DateFormatter from "../components/DateFormatter";
import VulnSummary from "../components/VulnsSummary";


const columns: GridColDef[] = [
  {
    field: 'project__project_name',
    headerName: 'Project', flex: 2, valueGetter: p => p.row.project.project_name
  },
  { field: 'pipeline_id', headerName: 'Pipeline', minWidth: 150 },
  {
    field: 'counters', headerName: 'Vulnerabilities',
    minWidth: 150,
    flex: 1,
    renderCell: (p) => <VulnSummary counts={p.row} />
  },
  {
    field: 'started_at', headerName: 'Started',
    renderCell: (p) => { return <DateFormatter date={p.row.started_at} /> }
  },
];

export default function Pipelines() {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<number>(0);
  const [orderingModel, setOrderingModel] = useState<GridSortModel>([{
    field: "started_at",
    sort: "desc"
  }])
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const navigate = useNavigate();

  const rowClick = (params: GridRowParams) => {
    navigate(`/pipeline/${params.row.pipeline_id}`)
  }

  const getItems = useCallback(async () => {
    setLoading(true)

    fetcher('api/pipelines', orderingModel, {}, paginationModel)
      .then(async res => {
        const { results, count } = await res.json();
        setData(results)
        setCount(count);
      })
      .finally(() => setLoading(false));
  }, [paginationModel, orderingModel]);

  useEffect(() => {
    getItems();
  }, [getItems])

  return <>
    <Box>
      <Bar />
      <DataGrid
        autoHeight
        paginationModel={paginationModel}
        sortModel={orderingModel}
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={setOrderingModel}
        paginationMode="server"
        loading={isLoading}
        pageSizeOptions={[10, 25, 100]}
        rowCount={count} rows={data} columns={columns}
        onRowClick={rowClick}
      />
      <Button onClick={() => getItems()}>Reload</Button>
    </Box>
  </>
}