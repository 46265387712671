import { Tab, Tabs } from "@mui/material";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import fetcher, { Filters, ReportData } from "../common/fetcher";


export default function PipelineEvents() {
  let { event_id } = useParams<"event_id">();
  let { filename } = useParams<"filename">();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<ReportData[]>([]);
  const [selectedReport, setSelectedReport] = useState<string | undefined>();
  const [orderingModel] = useState<GridSortModel>([{
    field: "build_created_at",
    sort: "desc"
  }])
  const [paginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState<Filters>({})
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedReport(filename)
  }, [filename]);


  const getItems = useCallback(async () => {
    // if (filters === {}) return;
    if (Object.keys(filters).length === 0) return

    setLoading(true)

    fetcher('api/reports', orderingModel, filters, paginationModel)
      .then(async res => {
        const { results } = await res.json();
        setData(results)
      })
      .finally(() => setLoading(false));
  }, [paginationModel, orderingModel, filters]);

  useEffect(() => {
    if (data && !selectedReport && data.length > 0) {
      navigate(data[0].filename)
      setSelectedReport(data[0].filename)
    }
  }, [data, navigate, selectedReport])

  useEffect(() => {
    if ('event_id' in filters) {
      getItems();
    }
  }, [filters, getItems])

  useEffect(() => {
    if (event_id) {
      setFilters(filters => {
        if (filters['event_id']?.value !== event_id) {
          return {
            ...filters,
            'event_id': {
              value: `${event_id}`,
              op: "="
            }
          }
        } else {
          return filters
        }
      })
    }

  }, [event_id])

  return <>
    {isLoading && <>Loading...</>}
    {!isLoading && <>
      <Tabs value={selectedReport || false}>
        {data.map(r => <Tab label={r.filename}
          key={r.id}
          to={r.filename}
          value={r.filename}
          component={Link} />)}
      </Tabs>
      <Outlet />
    </>
    }
  </>
}