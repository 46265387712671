interface VulnerabilityCounts {
    critical_count: number,
    major_count: number,
    medium_count: number,
    minor_count: number,
    none_count: number,
    unknown_count: number,
}

interface Props {
    counts: VulnerabilityCounts
}


export default function VulnSummary({ counts }: Props) {
    const c = [];
    if (counts.critical_count) {
        c.push(`Crit: ${counts.critical_count}`)
    }
    if (counts.major_count) {
        c.push(`Major ${counts.major_count}`)
    }
    if (counts.medium_count) {
        c.push(`Medium: ${counts.medium_count}`)
    }
    if (counts.minor_count) {
        c.push(`Minor: ${counts.minor_count}`)
    }
    if (counts.none_count) {
        c.push(`None: ${counts.none_count}`)
    }
    if (counts.unknown_count) {
        c.push(`Unk: ${counts.unknown_count}`)
    }

    if (c.length===0) {
        return <>-</>
    }

    return <>
        {c.join(", ")}
    </>
}