import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";

export type Filter = {
  value: string
  op: string
}
export type Filters = {
  [k: string]: Filter
}

export interface ProjectData {
  id: number
  project_id: number
  project_name: string
}

export interface AuthorData {
  id: number
  user_id: number
  name: string
  avatar_url: string
}

export interface EventData {
  id: number
  build_id: number
  build_name: string
  build_stage: string
  build_status: string
  build_created_at: Date
  build_started_at: Date
  build_duration: number
  commit_id: string
  commit_message: string
  source: string
  author: AuthorData
  project: ProjectData
}

export interface ReportData  {
  id: number
  filename: string
  source: string
  event: EventData
}

export default async function fetcher(api: string, sort: GridSortModel, filters: Filters, pagination?: GridPaginationModel, token?: string) {
  const ordering: string[] = []
  const filtering: string[] = []
  const page = pagination?.page || 0;
  const pageSize = pagination?.pageSize || 25;
  const offset = page * pageSize;
  let headers = {}

  if (token) {
    headers = {
      ...headers,
      "Authorization": `Bearer ${token}`
    }
  }

  for (const k in filters) {
    filtering.push(`${encodeURIComponent(k)}=${encodeURIComponent(filters[k].value)}`)
  }

  sort.forEach(it => {
    ordering.push(it.sort === "asc" ? encodeURIComponent(it.field) : `-${encodeURIComponent(it.field)}`)
  })

  // const url = `http://localhost:8000/${api}/`
  // const url = `https://webhook.postalytics-mq.com/${api}/`
  const url = `${process.env.REACT_APP_BACKEND!}/${api}/`
  const args = []
  args.push(`ordering=${ordering.join(',')}`)
  args.push(`offset=${offset}`)
  args.push(`limit=${pageSize}`)
  if (filtering.length > 0) {
    args.push(...filtering)
  }


  const clean_url = `${url}?${args.join("&")}`.replace(/([^:]\/)\/+/g, "$1")

  return fetch(clean_url, {headers})
}


