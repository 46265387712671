import { Avatar } from "@mui/material";
import { DataGrid, GridColDef, GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import fetcher, { Filters } from "../common/fetcher";
import DateFormatter from "../components/DateFormatter";
import VulnSummary from "../components/VulnsSummary";

const columns: GridColDef[] = [
  /*{ field: 'project__project_name', headerName: 'Project', flex: 1, minWidth: 250, valueGetter: p => p.row.project.project_name },*/
  { field: 'build_name', headerName: 'Job Name', flex: 1, minWidth: 150 },
  /*
  { field: "critical_count", headerName: "Critical" },
  { field: "major_count", headerName: "Major" },
  { field: "medium_count", headerName: "Medium" },
  { field: "minor_count", headerName: "Minor" },
  { field: "none_count", headerName: "None" },
  { field: "unknown_count", headerName: "Unknown" },
  */
  {
    field: 'counters', headerName: 'Vulnerabilities',
    minWidth: 150,
    flex: 1,
    renderCell: (p) => <VulnSummary counts={p.row} />
  },
  { field: 'build_status', headerName: 'Status', },
  {
    field: 'build_started_at', headerName: 'Started',
    renderCell: (p) => { return <DateFormatter date={p.row.build_started_at} /> }
  },
  {
    field: 'author__name',
    headerName: 'User Name',
    minWidth: 150,
    renderCell: row => {
      return <>
        <Avatar src={row.row.author.avatar_url} style={{ marginRight: "1rem" }} />
        {row.row.author.name}
      </>
    }
  }
];

export default function Events() {
  const { pipeline_id } = useParams<"pipeline_id">();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<number>(0);
  const [orderingModel, setOrderingModel] = useState<GridSortModel>([{
    field: "build_created_at",
    sort: "desc"
  }])
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState<Filters>({})
  const navigate = useNavigate();

  const getItems = useCallback(async () => {
    // if (filters === {}) return;
    if (Object.keys(filters).length === 0) return

    setLoading(true)

    fetcher('api/events', orderingModel, filters, paginationModel)
      .then(async res => {
        const { results, count } = await res.json();
        setData(results)
        setCount(count);
      })
      .finally(() => setLoading(false));
  }, [paginationModel, orderingModel, filters]);

  useEffect(() => {
    if ('pipeline_id' in filters) {
      getItems();
    }
  }, [filters, getItems])

  useEffect(() => {
    console.log("PipelineId", pipeline_id)
    if (pipeline_id) {
      setFilters(filters => {
        if (filters['pipeline_id']?.value !== pipeline_id) {
          return {
            ...filters,
            'pipeline_id': {
              value: `${pipeline_id}`,
              op: "="
            }
          }
        } else {
          return filters
        }
      })
    }
  }, [getItems, pipeline_id])

  return <>
    <DataGrid
      autoHeight
      disableRowSelectionOnClick
      paginationModel={paginationModel}
      sortModel={orderingModel}
      onPaginationModelChange={setPaginationModel}
      onSortModelChange={setOrderingModel}
      paginationMode="server"
      loading={isLoading}
      pageSizeOptions={[10, 25, 100]}
      rowCount={count} rows={data} columns={columns}
      onRowClick={(params) => navigate(`events/${params.row.id}`)}
    />
  </>
}