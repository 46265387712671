import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from "@mui/material"
import { Vulnerability } from "../common/dtos"
import VulnerabilityId from "./VulnerablityId"

interface Props {
    vuln?: Vulnerability
}

export default function VulnerabilityDetail({ vuln }: Props) {
    return <Box>
        {vuln &&
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="subtitle2">Name</Typography>
                    <Typography>{vuln.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2">Severity</Typography>
                    <Typography>{vuln.severity}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2">Description</Typography>
                    <Accordion>
                        <AccordionSummary>
                            <Typography textOverflow={"ellipsis"} overflow={"hidden"}
                                noWrap maxWidth={"md"}>
                                {vuln.description}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <pre>{vuln.description}</pre>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2">Categories</Typography>
                    <Typography>{vuln.categories.join(",")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2">Location</Typography>
                    <Typography>
                        <div>{vuln.filename}</div>
                        <div>Line(s) {vuln.start_line}{vuln.end_line && `-${vuln.end_line}`}</div>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2">GitLab's ID</Typography>
                    <Typography>{vuln.vulnerability_id || "None"}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2">CVE IDs</Typography>
                    <Typography>{vuln.identifiers.map(v => <VulnerabilityId id={v} />)}</Typography>

                </Grid>
            </Grid>
        }
    </Box>
}