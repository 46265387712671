import { LoaderFunctionArgs, Link as RouterLink, RouterProvider, createBrowserRouter } from "react-router-dom";

import { Link } from '@mui/material';
import fetcher, { ReportData } from "../common/fetcher";
import Events from "../pages/Events";
import PipelineEvents from "../pages/PipelineEvents";
import Pipelines from "../pages/Pipelines";
import { Pipeline } from "../common/dtos";
import Reports from "../pages/Reports";
import ReportDetails from "../pages/ReportDetails";

const pipelineLoader = async (params: LoaderFunctionArgs) => {
  const v = await fetcher(`api/pipelines`, [], {
    "pipeline_id": {
      op: "=",
      value: params.params.pipeline_id!,
    }
  });
  const data = await v.json();
  return data.results[0];
}

const reportLoader = async (params: LoaderFunctionArgs) => {

  const v = await fetcher(`api/reports`, [], {
    "filename": {
      op: "=",
      value: params.params.filename!,
    },
    "event_id": {
      op: "=",
      value: params.params.event_id!,
    }
  });
  const data = await v.json();
  return data.results[0];
}

const router = createBrowserRouter([
  {
    path: "/",
    handle: {
      crumb: (id: string) => <Link key={id} underline="hover" color="white" component={RouterLink} to="/">Home</Link>
    },
    children: [
      {
        index: true,
        element: <Pipelines />,
      },
      {
        path: "pipeline",
        children: [{
          path: ":pipeline_id",
          element: <PipelineEvents />,
          loader: pipelineLoader,
          handle: {
            crumb: (id: string, path: any, data: Pipeline) => <Link key={id} underline="hover" color="white" component={RouterLink} to={path}>
              {data.project.project_name}
              &nbsp;
              ({data.pipeline_id})
            </Link>
          },
          children: [{
            index: true,
            element: <Events />
          }, {
            path: "events/:event_id",
            element: <Reports />,
            handle: {
              //crumb: (id: string, path: any) => <Link key={id} underline="hover" color="white" component={RouterLink} to={path}>Reports</Link>
            },
            children: [{
              index: true,
              element: <>Nada</>
            }, {
              path: ":filename",
              loader: reportLoader,
              element: <ReportDetails />,
              handle: {
                crumb: (id: string, path: any, data: ReportData) => <Link key={id} underline="hover" color="white" component={RouterLink} to={path}>{data.filename}</Link>
              },

            }]
          }]
        }]
      },
    ]
  },
])
export default function Routes() {
  return <RouterProvider router={router} />
}