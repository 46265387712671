import { Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';

interface Props {
  date: string
}

export default function DateFormatter({ date }: Props) {
  return <Tooltip title={DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_FULL)}>
    <Typography>{DateTime.fromISO(date).toRelative()}</Typography>
  </Tooltip>
}