import BackIcon from '@mui/icons-material/ArrowBack';
import { AppBar, Avatar, Box, Breadcrumbs, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { useContext, useState } from 'react';
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import { useMatches, useNavigate } from 'react-router-dom';

export interface Props {
  showBack?: boolean
  back?: string;
}

export default function Bar({ showBack, back }: Props) {
  const nav = useNavigate();
  const { logOut, idTokenData } = useContext<IAuthContext>(AuthContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const matches = useMatches();

  let crumbs = matches
    .filter((match) => {
      let m: any = match.handle;
      return Boolean(m?.crumb)
    })
    .map((match) => {
      let m: any = match.handle;
      return m.crumb(match.id, match.pathname, match.data)
    })/*
    .filter(c => c.data).map(c => {
      let data: any = c.data;
      return 
      return `${c.pathname} - ${data.pipeline_id}`
    })*/
  console.log("Location", matches, crumbs)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }
  /*
  useEffect(() => {
    console.log(idToken, token, tokenData, idTokenData)
  }, [idToken, token, tokenData, idTokenData])
  */

  return <Box sx={{ flexGrow: 1 }}>
    <AppBar position="fixed">
      <Toolbar>
        {showBack &&
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => nav(-1)}
          >
            <BackIcon />
          </IconButton>}
        {back &&
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="Back"
            sx={{ mr: 2 }}
            onClick={() => nav(back)}
          >
            <BackIcon />
          </IconButton>}
        <Typography variant="h6" component="div" sx={{marginRight: "2rem"}}>
          Pipelines
        </Typography>
        <Breadcrumbs sx={{ flexGrow: 1 }} separator="-">
          {crumbs.map(i => i)}
        </Breadcrumbs>
        {
          idTokenData && <div>
            <IconButton size="large" onClick={handleMenu}>
              <Avatar alt={idTokenData.name} src={idTokenData.picture} />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={() => logOut()}>Logout</MenuItem>
            </Menu>
          </div>
        }
      </Toolbar>
    </AppBar>
    <Toolbar />
  </Box>;
}
