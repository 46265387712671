import React, { useContext, useEffect } from "react";
// import { useApi } from "./ApiProvider";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import { createCtx } from "./utils";
import { Button, Typography } from "@mui/material";

export type ApiContextType = {
  idToken?: string,
  fetcher?: string
};

type Props = {
  children: React.ReactNode;
};

const [useAppState, CtxProvider] = createCtx<ApiContextType>();

const StateProvider = ({ children }: Props) => {
  //const api = useApi();
  const { idToken, login, loginInProgress } = useContext<IAuthContext>(AuthContext);

  /*
  useEffect(() => {
    api.tenants.tenantControllerFindAll().then((tenants) => {
      setTenants(tenants);
      setSelectedTenant(tenants[0]);
    });
  }, [api]);
  */  

  return (
    <CtxProvider
      value={{
        idToken        
      }}
    >
      { !idToken && !loginInProgress && <Button onClick={() => login()}>Login</Button>}
      { !idToken && loginInProgress && <Typography>Logging in</Typography>}
      { idToken && !loginInProgress && children}
    </CtxProvider>
  );
};

export { StateProvider, useAppState };

